<template>
	<div>
		<transition
			name="custom-classes-transition"
			enter-active-class="animate__animated animate__slideInUp"
			leave-active-class="animate__animated animate__slideOutDown"
		>
			<div
				v-show="!showChatOptions"
				class="clickable is-flex is-align-items-center online-help"
				@click="showChatOptions = true"
			>
				<div class="mr-7">
					<b-icon
						icon="chat-outline"
						class="chat-icon primary-color"
					/>
				</div>
				<div class="pl-2">
					<p class="primary-color join-text is-size-7 has-text-weight-bold">
						Fale conosco
					</p>
				</div>
			</div>
		</transition>

		<transition
			name="custom-classes-transition"
			enter-active-class="animate__animated animate__slideInUp"
			leave-active-class="animate__animated animate__slideOutDown"
		>
			<div
				v-show="showChatOptions"
				class="help-menu"
			>
				<div
					class="clickable help-menu-container-title is-flex is-flex-direction-column is-align-items-center"
					@click="showChatOptions = false"
				>
					<div class="pt-4 is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
						<div>
							<p class="is-size-5 has-text-weight-bold">
								Fale conosco
							</p>
							<p class="is-size-7 ">
								Segunda à sexta-feira
							</p>
						</div>
					</div>

					<div class="px-2 my-4">
						<p
							v-if="$store.state.ie.sigla == 'uns'"
							class="is-size-7 "
						>
							Suporte geral e rematrícula: 8h às 19h.
						</p>
						<p
							v-else
							class="is-size-7"
						>
							Suporte geral e rematrícula: 8h às 19h30.
						</p>
						<p class="is-size-7">
							Trancamento e cancelamento: 8h às 18h.
						</p>
					</div>
				</div>

				<div
					class="clickable pt-4 pb-2 is-flex is-flex-direction-column is-align-items-center"
					@click="sendToWhatsApp"
				>
					<div class="is-flex is-align-items-center is-flex-direction-column mb-3">
						<p class="is-size-7">
							Salve nos seus contatos!
						</p>
						<p class="is-size-7 has-text-weight-bold">
							(11) 3298-7730
						</p>
					</div>
					<div class="is-flex is-align-items-center is-flex-direction-column mb-3">
						<UIImage
							class="whats-icon mr-1"
							src="whatsapp.svg"
						/>
					</div>
					<div class="is-flex is-align-items-center is-flex-direction-column mb-3">
						<p class="is-size-7 has-text-weight-bold">
							Atendimento exclusivo
						</p>
						<p class="is-size-7 has-text-weight-bold">
							via WhatsApp
						</p>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import UIImage from "@components/UIImage";

export default {
	name: 'ChatMenu',
	components: {
		UIImage
	},
	data() {
		return {
			showChatOptions: false,
		}
	},
	methods: {

		sendToWhatsApp() {
			this.showChatOptions = false;
			window.open('https://api.whatsapp.com/send?phone=551132987730&text=Oi Estudante! Clique em enviar para falar conosco!', '_blank');
		},
	}

}
</script>

<style scoped>
.online-help {
  position: fixed;
  background-color: white;
  bottom: 30px;
  right: 30px;
  border: 2px solid var(--primary);
  border-radius: 99px;
  padding: 5px 10px;
  width: 134px;
  height: 42px;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
}

.join-text {
  line-height: 12px;
}

.chat-icon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.whats-icon {
  width: 33px !important;
}

.clickable {
  cursor: pointer;
}

.help-menu {
  width: 295px;
  position: fixed;
  background-color: white;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
  bottom: 0px;
  right: 27px;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
	z-index: 100;
}

.help-menu-container-title {
  height: 129px;
  background-color: var(--primary);
  color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

hr {
  background-color: #ccc;
  margin: 7px 0;
  height: 1px;
}
</style>
