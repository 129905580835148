<template>
	<b-modal
		v-model="isCardModalActive"
		:width="'60%'"
		scroll="keep"
		:can-cancel="false"
	>
		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>
		<div class="card">
			<div class="card-content is-flex is-flex-direction-column is-align-items-center">
				<h1 class="is-flex title is-1 has-text-centered pt-5">
					{{ notification.titulo }}
				</h1>

				<div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between is-flex-grow-2">
					<div v-html="notification.texto" />

					<b-button
						class="mb-2 mt-5"
						label="Fechar"
						type="is-primary is-rounded"
						@click="closeNotification"
					/>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>

export default {
	name: "AlertNotification",
	props: {
		notification: {
			type: [Object],
			default: () => ({}),
			useDefaultForNull: true,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			isCardModalActive: true,
		};
	},
	created() {
		this.loading = false;
	},
	methods: {
		closeNotification() {
			this.$store.state.alertNotifications.shift();
			this.$store.commit('setAlertNotifications', this.$store.state.alertNotifications)
		}
	},
};
</script>

<style scoped>

</style>
