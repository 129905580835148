<template>
	<div
		class="logo"
		:style="{maxWidth:maxwidth}"
	>
		<UIImage
			size="224:60"
			bgsize="contain"
			:src="path"
			alt=""
		/>
	</div>
</template>

<script>
import UIImage from '@components/UIImage';

export default {
	name: 'Logo',
	components:{
		UIImage
	},
	props: {
		maxwidth:{
			type: [String],
			default: '100%',
			required: false,
			useDefaultForNull: true
		}
	},
	computed:{
		path(){
			return this.$store.state.ie.logo;
		}
	}
}
</script>

<style lang="scss" scoped>
.logo{
  display: flex;
  width: 100vw;
  align-items: center;
  padding: 0;
  overflow: visible;
  user-select: none;

  img{
    background-size: 98% auto !important;
  }
}
</style>