<template>
	<div
		class="accordion"
		:class="{'is-active': active}"
	>
		<div
			class="columns is-marginless btn-toggle is-mobile"
			@click="toggleActive"
		>
			<div class="column is-narrow icon-alert">
				<b-icon
					:icon="icon"
					size="is-small"
				/>
			</div>
			<div class="column">
				<h3 class="title is-6">
					{{ get(data, 'titulo', '') }}
				</h3>
			</div>
			<div class="column is-narrow icon-arrow-down is-paddingless">
				<b-icon icon="chevron-down" />
			</div>
		</div>
		<div class="columns is-marginless content is-mobile">
			<div class="column is-10 is-offset-1">
				<p
					class="paragraph mb-5"
					v-html="get(data, 'texto', '')"
				/>
				<b-button
					v-show="isConfirmative"
					type="is-primary"
					rounded
					class="mb-2"
					@click.prevent="onRead()"
				>
					Marcar como lida
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash';
import http from '@commons/http';

export default {
	name: "Notification",
	props: {
		data: {
			type: [Object],
			default: () => {},
			required: true,
			useDefaultForNull: true
		},
	},
	data() {
		return {
			active: false,
			lida: false,
		}
	},
	computed: {
		icon() {
			const { tipo } = this.data;

			return tipo == 1 ? 'bell-ring' : 'alert-outline';
		},
		isConfirmative() {
			const { tipo } = this.data;

			return tipo == 2 && !this.lida;
		}
	},
	created() {
		this.lida = this.data.lida;
	},
	methods: {
		toggleActive() {
			this.active = !this.active;

			const { tipo } = this.data;

			if (tipo == 1 && this.active) this.onRead();
		},
		async onRead() {
			const { notu_id, noti_id } = this.data;

			if (this.lida) {
				return;
			}

			const data = notu_id ? { id: `${noti_id}-${notu_id}`} : { id: `${noti_id}-`};

			await http.post(
				'/v1/aluno/notificacoes/marcar_lido',
				new URLSearchParams(data).toString()
			).then(() => {
				this.lida = true;
			});
		},
		get
	}
}
</script>

<style lang="scss" scoped>
  @import "@styles/_variables";

  .accordion{
    .btn-toggle{
      user-select: none;
      cursor: pointer;

      &:hover{
          background-color: #eee;
      }

      .title.is-6 {
        font-size: 14px;
        line-height: 1.4;
      }

      .icon-alert,
      .icon-arrow-down {
        display: inline-flex;
        display: inline-flex;
        align-items: flex-start;
        width: 32px;
        color: $primary;
        color: var(--primary);
      }
      .icon-alert .icon {
        font-size: 18px;
        position: relative;
        left: 7px;
        top: 3px;
      }
      .icon-arrow-down .icon {
        transition: all 300ms ease 0ms;
        transform: rotate(0deg);
        position: relative;
        top: 10px;
        left: -7px;
      }
    }

    .content {
      transform: translateY(20px);
      overflow: hidden;
      transition: all 300ms ease 0ms;
      transform-origin: 0 0;
      height: 0;
      opacity: 0;

      .button {
        width: 100%;
        padding: 21px 0px;
      }
    }

    &.is-active {
      .btn-toggle .icon-arrow-down .icon{
        transform: rotate(180deg);
      }
      .content {
        transform: translateY(0);
        height: auto;
        opacity: 1;
      }
    }
  }
</style>
