<template>
	<div
		class="modal-card is-active"
		style="width: auto"
		scroll
	>
		<section class="modal-card-body px-6 pb-0 is-flex is-flex-direction-column is-align-items-center">
			<h1 class="is-flex title is-1 has-text-centered pt-5">
				{{ notification.titulo }}
			</h1>

			<div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between is-flex-grow-2 dynamic-content pt-6 px-4">
				<div v-html="notification.texto" />

				<b-button
					class="mb-5"
					:label="notification.botao_acao || 'Estou ciente'"
					type="is-primary is-rounded"
					@click="confirmNotification"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import http from "@commons/http";

export default {
	name: "PopupNotification",
	props: {
		notification: {
			type: [Object],
			default: () => ({}),
			useDefaultForNull: true,
			required: true
		}
	},
	beforeCreate() {
		this.$store.dispatch('ui/loading', true);
	},
	created() {
		this.$store.dispatch('ui/loading', false);
	},
	methods: {
		async confirmNotification() {
			this.$store.dispatch('ui/loading', true);

			let data = { noti_id: this.notification.noti_id };

			if (this.notification.notu_id) {
				data.notu_id = this.notification.notu_id;
			}

			await http.post(
				'/v1/aluno/notificacoes/aceite',
				new URLSearchParams(data).toString()
			).then(() => {
				this.$store.state.popupNotifications.shift();
				this.$store.commit('setPopupNotifications', this.$store.state.popupNotifications)
				this.$store.dispatch('ui/loading', false);
			}).catch(() => {
				this.$store.dispatch('ui/loading', false);
				this.$buefy.toast.open({
					message: 'Erro ao enviar a confirmação. Retornaremos essa notificação em breve. Passando para próxima...',
					type: 'is-danger',
					duration: 5000
				});

				setTimeout(() => {
					this.$store.state.popupNotifications.shift();
					this.$store.commit('setPopupNotifications', this.$store.state.popupNotifications)
				}, 5000)
			});
		}
	},
};
</script>

<style scoped>
  .modal-card {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 100% !important;
    max-height: 100%;
  }

  .dynamic-content {
    background-color: #F2F2F2;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
</style>
