<template>
	<div
		class="modal-card is-active"
		style="width: auto"
		scroll
	>
		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<section
			:style="{ 'max-height': windowHeight }"
			class="modal-card-body px-6"
		>
			<div
				v-if="isGrad"
				class="overflow-text-doc"
			>
				<h1 class="title is-1 has-text-centered py-6">
					Termo de entrega de documentos <br />
					para ingressar na graduação
				</h1>

				<div class="justify-text">
					<p class="px-6 py-3">
						Eu, {{ nome }}, portador do CPF {{ aluno.cpf }}, declaro estar
						ciente e concordar com os termos do Edital do Processo Seletivo, no
						tocante à necessidade da entrega da documentação obrigatória para
						efetivação e manutenção da matrícula.
					</p>

					<p class="px-6 py-3">
						Estou ciente que para obter vínculo de matrícula com a instituição é
						necessário aceitar o contrato de prestação de serviços educacionais
						de forma digital e realizar o envio digital dos seguintes
						documentos:
					</p>

					<h5 class="px-6 pt-5 content is-small title">
						DOCUMENTOS OBRIGATÓRIOS PARA PRÉ-MATRÍCULA:
					</h5>

					<ul class="px-6 pb-3">
						<li><strong> · </strong>Comprovante de identidade com foto;</li>
						<li><strong> · </strong>CPF.</li>
					</ul>

					<h5 class="px-6 pt-5 content is-small title">
						DOCUMENTOS OBRIGATÓRIOS PÓS MATRÍCULA (ENVIO EM ATÉ 60 DIAS APÓS O
						INÍCIO DAS AULAS):
					</h5>

					<ul class="px-6 pb-3">
						<li>
							<strong> · </strong> Comprovante de Conclusão do Ensino Médio
							emitido pela escola de origem em data anterior ao da matrícula;
						</li>

						<li>
							<strong> · </strong> Histórico e certificado de Conclusão do
							Ensino Médio (para quem enviou apenas a declaração para a
							matrícula);
						</li>

						<li><strong> · </strong> Certidão de nascimento ou casamento;</li>

						<li>
							<strong> · </strong> Título de eleitor (brasileiros entre 18 e 70
							anos);
						</li>

						<li>
							<strong> · </strong> Comprovante de quitação com as obrigações
							eleitorais (brasileiros entre 18 e 70 anos);
						</li>

						<li>
							<strong> · </strong> Prova de quitação com o serviço militar
							(brasileiros do sexo masculino e entre 18 e 70 anos);
						</li>

						<li>
							<strong> · </strong> CPF e documento de identidade do responsável
							legal (para menores de 18 anos);
						</li>

						<li v-if="aluno.ie == 'UniSul'">
							<strong> · </strong> Comprovante de vacinação contra rubéola
							(brasileiras do sexo feminino de até 40 anos e matrícula em
							instituição do estado de Santa Catarina - Lei estadual
							10.196/1996, art. 2, parágrafo único);
						</li>

						<li>
							<strong> · </strong> Histórico Escolar do Ensino Médio traduzido
							(para quem concluiu o curso no exterior);
						</li>

						<li>
							<strong> · </strong> Declaração de equivalência do Ensino Médio e
							cópia da publicação no Diário do Estado (para quem concluiu o
							curso no exterior);
						</li>

						<li>
							<strong> · </strong> Comprovante de residência atualizado (últimos
							90 dias);
						</li>

						<li>
							<strong> · </strong> Passaporte, identidade de estrangeiro, CPF,
							caso houver, e visto do tempo de permanência no Brasil (para
							estrangeiros);
						</li>

						<li>
							<strong> · </strong> Boletim do Enem (quando a forma de ingresso
							for Enem);
						</li>

						<li v-if="aluno.ie == 'UniSul'">
							<strong> · </strong> Histórico Escolar do Ensino superior (quando
							a forma de ingresso for Transferência Externa ou Obtenção de Novo
							Título);
						</li>

						<li v-else>
							<strong> · </strong> Histórico Escolar do Ensino superior (quando
							for para Obtenção de Novo Título);
						</li>

						<li>
							<strong> · </strong> Diploma do Ensino Superior (quando a forma de
							ingresso for Obtenção de Novo título);
						</li>

						<li>
							<strong> · </strong> Diploma traduzido, juramentado e revalidado,
							para alunos que concluíram o Ensino Superior em outro País (quando
							a forma de ingresso for Transferência Externa ou Obtenção de Novo
							Título);
						</li>

						<li>
							<strong> · </strong> Histórico do Ensino Superior traduzido,
							juramentado e revalidado, para alunos que concluíram o Ensino
							Superior em outro País (quando a forma de ingresso for Obtenção de
							Novo título);
						</li>
					</ul>

					<p class="px-6 py-3">
						Comprometo-me a enviar os documentos pós matrícula em até 60
						(sessenta) dias após a data prevista para o início das aulas, por
						meio do Portal do Estudante.
					</p>

					<p class="px-6 py-3">
						Estou ciente que a não apresentação dos documentos acima listados no
						prazo aqui estabelecidos, poderá implicar no bloqueio para a
						renovação da minha matrícula e/ou no cancelamento do contrato e
						vínculo com a instituição a qualquer tempo.
					</p>

					<p class="px-6 py-3">
						Caso haja o cancelamento do contrato por esta hipótese, poderá haver
						a retenção de valores e/ou aplicação de multa, conforme previsto no
						Contrato de Prestação de serviços Educacionais.
					</p>

					<p class="px-6 py-3">
						Estou ciente que a condição imprescindível para o ingresso no Ensino
						Superior é a conclusão do Ensino Médio em data anterior ao da
						matrícula, sob pena de cancelamento da matrícula, conforme
						estabelecido no Contrato de Prestação de Serviços Educacionais e na
						legislação pertinente.
					</p>

					<p class="px-6 py-3">
						Declaro que estou ciente e de acordo com as condições acima
						previstas, razão pela qual firmo o presente TERMO para todos os
						efeitos legais.
					</p>
				</div>

				<p class="px-6 py-3">
					{{ aluno.cidade }}, {{ hoje }}
				</p>
			</div>

			<div
				v-else-if="isPos"
				class="overflow-text-doc"
			>
				<h1 class="title is-1 has-text-centered py-6">
					Termo de entrega de documentos <br>
					para ingressar na pós graduação
				</h1>

				<div class="justify-text">
					<p class="px-6 py-3">
						Eu, {{ nome }}, portador do CPF {{ aluno.cpf }}, declaro estar
						ciente e concordar com os termos do Edital do Processo Seletivo, no
						tocante à necessidade da entrega da documentação obrigatória para
						efetivação e manutenção da matrícula.
					</p>

					<p class="px-6 py-3">
						Estou ciente que para obter vínculo de matrícula com a instituição é
						necessário aceitar o contrato de prestação de serviços educacionais
						de forma digital e realizar o envio digital dos seguintes
						documentos:
					</p>

					<h5 class="px-6 pt-5 content is-small title">
						DOCUMENTOS OBRIGATÓRIOS PARA PRÉ-MATRÍCULA:
					</h5>

					<ul class="px-6 pb-3">
						<li><strong> · </strong>Comprovante de identidade com foto;</li>
						<li><strong> · </strong>CPF.</li>
					</ul>

					<h5 class="px-6 pt-5 content is-small title">
						DOCUMENTOS OBRIGATÓRIOS PÓS MATRÍCULA (ENVIO EM ATÉ 60 DIAS APÓS O
						INÍCIO DAS AULAS):
					</h5>

					<ul class="px-6 pb-3">
						<li>
							<strong> · </strong> Comprovante de Colação de Grau, emitido pela
							universidade de origem, em data anterior ao desta matrícula;
						</li>

						<li>
							<strong> · </strong> Diploma do curso de graduação (frente e
							verso);
						</li>

						<li>
							<strong> · </strong> Histórico Escolar do curso de Graduação
							(frente e verso, se houver);
						</li>

						<li>
							<strong> · </strong> RG com foto (Caso tenha entregado a CNH
							(carteira de motorista) ou CTPS (carteira de trabalho) na
							pré-matrícula);
						</li>

						<li><strong> · </strong> Certidão de nascimento ou casamento;</li>
						<li>
							<strong> · </strong> Título de eleitor (brasileiros entre 18 e 70
							anos);
						</li>

						<li>
							<strong> · </strong> Comprovante de quitação com as obrigações
							eleitorais (brasileiros entre 18 e 70 anos);
						</li>

						<li>
							<strong> · </strong> Prova de quitação com o serviço militar
							(brasileiros do sexo masculino e entre 18 e 70 anos);
						</li>

						<li>
							<strong> · </strong> Comprovante de residência atualizado (últimos
							90 dias);
						</li>

						<li>
							<strong> · </strong> Diploma traduzido, juramentado e revalidado,
							para alunos que concluíram o Ensino Superior em outro País (quando
							a forma de ingresso for Transferência Externa ou Obtenção de Novo
							Título);
						</li>

						<li>
							<strong> · </strong> Passaporte, identidade de estrangeiro, CPF,
							caso houver, e visto do tempo de permanência no Brasil,
							<strong>para candidatos estrangeiros</strong>;
						</li>

						<li v-if="aluno.ie == 'UniSul'">
							<strong> · </strong> Comprovante de vacinação contra rubéola
							(brasileiras do sexo feminino de até 40 anos e matrícula em
							instituição do estado de Santa Catarina - Lei estadual
							10.196/1996, art. 2, parágrafo único).
						</li>
					</ul>

					<p class="px-6 py-3">
						Comprometo-me a enviar os documentos pós-matrícula em até 60
						(sessenta) dias após a data prevista para o início das aulas, por
						meio do Portal do Estudante.
					</p>

					<p class="px-6 py-3">
						Estou ciente que a não apresentação dos documentos acima listados no
						prazo aqui estabelecidos, poderá implicar no bloqueio para a
						renovação da minha matrícula e/ou no cancelamento do contrato e
						vínculo com a instituição a qualquer tempo.
					</p>

					<p class="px-6 py-3">
						Caso haja o cancelamento do contrato por esta hipótese, poderá haver
						a retenção de valores e/ou aplicação de multa, conforme previsto no
						Contrato de Prestação de serviços Educacionais.
					</p>

					<p class="px-6 py-3">
						Estou ciente que a condição imprescindível para o ingresso na
						Pós-graduação é o Certificado de Colação de Grau na Graduação em
						data anterior ao da matrícula, sob pena de cancelamento da
						matrícula, conforme estabelecido no Contrato de Prestação de
						Serviços Educacionais e na legislação pertinente.
					</p>

					<p class="px-6 py-3">
						Declaro que estou ciente e de acordo com as condições acima
						previstas, razão pela qual firmo o presente TERMO para todos os
						efeitos legais.
					</p>

					<p class="px-6 py-3">
						<small>
							<strong>Importante:</strong> Conforme o previsto na Resolução
							CNE/CES nº 01/2018, os cursos de especialização são abertos a
							candidatos diplomados em cursos de graduação, não sendo permitido
							a egressos de cursos sequenciais por campo de saber a sua
							matrícula e certificação. Caso seja identificado que o Diploma
							apresentado para a formalização de matrícula trate de conclusão em
							curso sequencial por campo de saber, fica a instituição autorizada
							a não formalizar o vínculo de matrícula.
						</small>
					</p>
				</div>

				<p class="px-6 py-3">
					{{ aluno.cidade }}, {{ hoje }}
				</p>
			</div>

			<form action="">
				<div class="columns pt-3 pb-6 px-6">
					<div
						v-if="isGrad"
						class="column is-5 has-text-centered"
					>
						<span
							class="is-block pt-2"
						>
							Declaro ainda, que a data de Conclusão do meu Ensino Médio é:
						</span>
					</div>

					<div
						v-else-if="isPos"
						class="column is-5 has-text-centered"
					>
						<span class="is-block pt-2">
							Declaro ainda, que a data de colação de grau na Graduação é:
						</span>
					</div>

					<div class="column is-3 has-text-centered">
						<b-field>
							<b-select
								v-model="conclusionMonth"
								placeholder="Mês"
								expanded
								size="is-medium"
							>
								<option value="01">Janeiro</option>
								<option value="02">Fevereiro</option>
								<option value="03">Março</option>
								<option value="04">Abril</option>
								<option value="05">Maio</option>
								<option value="06">Junho</option>
								<option value="07">Julho</option>
								<option value="08">Agosto</option>
								<option value="09">Setembro</option>
								<option value="10">Outubro</option>
								<option value="11">Novembro</option>
								<option value="12">Dezembro</option>
							</b-select>
						</b-field>
					</div>

					<div class="column is-3 has-text-centered">
						<b-field>
							<b-select
								v-model="conclusionYear"
								placeholder="Ano"
								expanded
								size="is-medium"
							>
								<option
									v-for="year in years"
									:key="year"
								>
									{{ year }}
								</option>
							</b-select>
						</b-field>
					</div>
				</div>
			</form>

			<div class="columns">
				<div class="column is-6 has-text-centered">
					<b-button
						:disabled="formDisable"
						type="is-primary"
						outlined
						class="is-primary is-rounded"
						@click.native="send(false)"
					>
						Estou ciente e entrego depois
					</b-button>
				</div>

				<div class="column is-6 has-text-centered">
					<b-button
						:disabled="formDisable"
						type="is-primary"
						class="is-primary is-rounded"
						@click.native="send(true)"
					>
						Estou ciente e quero entregar agora
					</b-button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import http from '@commons/http';
import { isNil } from 'lodash';

export default {
	name: 'DocumentsTerm',

	data() {
		return {
			conclusionMonth: null,
			conclusionYear: null,
			seeNow: false,
			seeLater: false,
			isGrad: false,
			isPos: false,
			loading: false,
			aluno: {
				cidade: '',
				nome: '',
				dataNascimento: '',
				cpf: '',
				ie: '',
				alun_matricula: '',
				periodo: '',
				cursosNaoAceitos: ''
			}
		};
	},

	computed: {
		years() {
			return Array(100)
				.fill((new Date()).getFullYear())
				.map((year, i) => year - i);
		},

		nome() {
			if (!this.$store.getters['aluno/dadosPessoais/temNomeSocial']) {
				return this.$store.state.aluno.dadosPessoais.nome;
			}

			return this.$store.state.aluno.dadosPessoais.nomeSocial
				+ ' (NOME CIVIL: '
				+ this.$store.state.aluno.dadosPessoais.nome
				+ ')'
			;
		},

		formDisable() {
			if (isNil(this.conclusionYear) || isNil(this.conclusionMonth)) {
				return true;
			}

			const todayDate = new Date();
			const conclusionDate = new Date(
				this.conclusionYear,
				this.conclusionMonth - 1,
				todayDate.getDay()
			);

			if (conclusionDate > todayDate) {
				return true;
			}

			return false;
		},

		hoje() {
			return new Date().toLocaleDateString('pt-br');
		},

		windowHeight() {
			return window.height;
		},
	},

	created() {
		this.aluno = {
			cpf: this.$store.state.alunoCPF,
			dataNascimento: this.$store.state.alunoDataNascimento,
			cidade: this.$store.state.alunoCidade,
			ie: this.$store.state.ie.shortname,
			alun_matricula: this.$store.state.alunoMatricula,
			periodo: this.$store.state.periodo,
			cursosNaoAceitos: this.$store.state.cursosNaoAceitos
		};

		this.isGrad = this.$store.state.isGrad;
		this.isPos = this.$store.state.isPos;
	},

	methods: {
		send(redirect) {
			this.loading = true;
			let requestBody = this.mountRequestBody();

			requestBody = new URLSearchParams(Object.entries(requestBody)).toString();

			http
				.post('/v1/aluno/check-documentos', requestBody)
				.then(response => {
					if (response.data.status == 'success') {
						if (redirect) {
							this.$router.push('/envio-documentos');
						}

						this.$store.commit('setAceiteTermoDocumentos', true);
						this.$emit('close');
						this.loading = false;
					}
				})
				.catch(() => {
					this.$buefy.toast.open({
						message: 'Erro ao enviar a confirmação.',
						type: 'is-danger'
					});
				});
		},

		mountRequestBody() {
			if (this.isGrad) {
				return {
					ano_conclusao_em: this.conclusionYear,
					mes_conclusao_em: this.conclusionMonth,

					nome: this.nome,
					cidade: this.aluno.cidade,
					cpf: this.aluno.cpf,
					ie: this.aluno.ie,
					isGrad: true,
					alun_matricula: this.aluno.alun_matricula,
					periodo: this.aluno.periodo,
					cursosNaoAceitos: this.aluno.cursosNaoAceitos
				};
			}

			if (this.isPos) {
				return {
					ano_conclusao_graduacao: this.conclusionYear,
					mes_conclusao_graduacao: this.conclusionMonth,

					nome: this.nome,
					cidade: this.aluno.cidade,
					cpf: this.aluno.cpf,
					ie: this.aluno.ie,
					isPos: true,
					alun_matricula: this.aluno.alun_matricula,
					periodo: this.aluno.periodo,
					cursosNaoAceitos: this.aluno.cursosNaoAceitos
				};
			}

			return false;
		}
	}
};
</script>

<style scoped>
.overflow-text-doc {
	height: 40vh !important;
	overflow-y: scroll;
}

.justify-text {
	text-align: justify;
	text-justify: inter-word;
}
</style>
