<template>
	<span
		v-if="jsFunction"
		class="card-button"
		@click="$emit('click')"
	>
		<slot />
	</span>
	<a
		v-else-if="isExternalURL"
		:href="href"
		:target="target"
	>
		<slot />
	</a>

	<span
		class="card-disabled"
		v-else-if="isDisabled"
	>
		<slot />
	</span>

	<router-link
		v-else
		:to="href"
	>
		<slot />
	</router-link>

	
</template>

<script>
import { isNil, isEmpty } from 'lodash';

const externalURL = /^https?:\/\//i;

export default {
	name: 'Anchor',

	props: {
		href: {
			type: [String, Object],
			default: () => {
				return "";
			}
		},

		target: {
			type: String,
			required: false,
			default: '_self',
		},

		jsFunction: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		isExternalURL() {
			if (isNil(this.href) || isEmpty(this.href)) {
				return;
			}

			return this.href.match(externalURL);
		},
		isDisabled(){
			return isNil(this.href);
		}
	},
};
</script>

<style scoped>
.card-disabled {
	opacity: 0.6;
	filter: grayscale(100%);
	cursor: not-allowed;
}
.card-button {
	cursor: pointer;
}


</style>
